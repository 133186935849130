<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout grid-list-md pa-0 relative v-if="!reusecomponent">
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title" v-if="!issuereport">
            Book Issue List
            <v-spacer></v-spacer>
            <add-button
              class="ml-2"
              id="btn-add-staff"
              permission="library-create"
              @action="showIssueDialog = true,display_book=true,getBooks()"
              > Issue New Book
            </add-button>
            
          </v-card-title>
         
          <v-card-title class="title" v-else> Book Issue Report 
            <v-spacer></v-spacer>
             <print-buttons
              v-if="$auth.can('library-download') && form.items.data.length > 0"
              :passAuthToReport="true"
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
              :downloadUrl="downloadUrl"
              :pdf="false"
              :excel="true"
            ></print-buttons>
          </v-card-title>
          <v-card outlined v-if="!issuereport">
            <v-card-title outlined>
              <v-flex xs3 >
                <v-text-field
                  label="Search By Book Name"
                  v-model="book_name"
                  outlined
                  dense
                  class="mt-1 "
                ></v-text-field>
              </v-flex>
              <v-flex xs3 style="margin-top: 5px;">
                <v-student-search-field
                  @onClear="onClear"
                  @onSelectStudent="filterByStudent"
                ></v-student-search-field>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-card outlined v-if="issuereport">
            <v-card-title outlined>
              <v-flex xs3>
                <v-student-search-field
                  @clear="onClear"
                  @onSelectStudent="selectStudent"
                ></v-student-search-field>
              </v-flex>
              <v-flex xs2>
                <v-grade-field v-model="book_filter.grade_id" :withAll='true'></v-grade-field>
              </v-flex>
              <v-flex xs2>
                <v-calendar-field
                  id-val="from_date"
                  v-model="book_filter.frmDate"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2>
                  <v-calendar-field
                  id-val="to_date"
                  v-model="book_filter.toDate"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2>
                <v-autocomplete
                  :items="book_status"
                  hide-no-data
                  class="pa-0"
                  label="Book Status"
                  outlined
                  dense
                  multiple
                  v-model = "book_filter.book_status"
                />
              </v-flex>
              <v-flex xs1>
                <search-button :permission="true" @action="get()">
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="issuereport ? showHeaders :headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td align="left">{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.book_name }}    
                  <div v-if="item.book_code">
                    <span style="font-size: 10px; color: #666"
                      >(Book Code: {{ item.book_code }})</span
                    >
                  </div>          
                </td>
                <td class="text-xs-left">
                  {{ item.student_name }}
                  <div>
                    <span style="font-size: 10px; color: #666"
                      >(Enroll Code: {{ item.enroll_code }})</span
                    >
                  </div>
                </td>
                <td class="text-xs-left">
                  {{ item.grade || "-" }}
                  <div>
                    <span
                      style="font-size: 10px; color: #666"
                      v-if="item.section"
                    >
                    ({{ item.section }})
                      <!-- ({{ item.enroll_code || "" }}) -->
                    </span>
                  </div>
                  
                </td>

                <td class="text-xs-left">{{ item.issue_date }}</td>
                <td class="text-xs-left">{{ item.issue_till_date }}</td>
                <td class="text-xs-left">{{ item.return_date }}</td>
                <td class="text-xs-left">
                  <v-chip
                    small
                    label
                    text-color="white"
                    :color="item.issue_till_date_english.compareDate(item.issue_till_date_english) ? 'error': item.status === 'issued' ? 'primary' : item.status === 'returned'? 'amber' : 'orange'"
                    class="mt-1"
                    >
                    <!-- :color=" item.status === 'issued' ? 'primary' : item.status === 'returned'? 'amber' : 'orange'" -->
                    <!-- color= "primary" -->
                    {{ item.status }}
                  </v-chip>
                </td>
                <!-- v-if="item.status !== 'returned'" -->
                <td class="text-right" v-if="!issuereport">
                  <v-btn
                    v-if="$auth.can('library-update') && item.status !== 'returned'"
                    x-small
                    outlined="outlined"
                    color="primary"
                    @click="
                        (returnBook = true),
                        returnKey= returnKey+1,
                        (bookreturn.id = item.id),
                        (bookreturn.book_id = item.book_id),
                        (bookreturn.isbn_number = item.isbn_number),
                        (bookreturn.enroll_code = item.enroll_code),
                        (bookreturn.issue_till_date = item.issue_till_date),
                        // (bookreturn.issue_date = item.issue_date)
                        (bookreturn.book_code = item.book_code)
                    "
                  >
                    <strong class="ml-1"
                      ><i class="fas fa-exchange-alt"></i> Return</strong
                    >
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showIssueDialog" persistent max-width="400px">
      <v-card >
        <v-card-title class="primary white--text">
          <span class="title">Issue Book</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-student-search-field
              name="name"
              required
              :rules="nameRules"
              @onSelectStudent="selectStudent"
            ></v-student-search-field>
            <v-calendar-field
              style="margin-top:2px"
              name="IssueYear"
              required
              id-val="issue_year"
              label="Issue Date"
              v-model="form.issue_date"
              :error-messages="form.errors.get('issue_year')"
              :rules="[(v) => !!v || 'Issue Year is required']"
              :key="compKey"
            >
            </v-calendar-field>
            <v-calendar-field
              style="margin-top:8px"
              required
              id-val="issue_till_date"
              label="Issue Till Date"
              name="IssueTillDate"
              v-model="form.issue_till_date"
              :error-messages="form.errors.get('IssueTillDate')"
              :rules="[(v) => !!v || 'Issue Till Date is required']"
            >
            </v-calendar-field>
            <v-autocomplete
              v-if="display_book"
              autocomplete="off"
              :search-input.sync="searchbook"
              :items="bookLists"
              label="Book Name"
              outlined
              dense
              class="pa-0 mt-3"
              v-model="searchBookId"
            />
            <v-autocomplete
              autocomplete="off"
              :search-input.sync="search"
              :items="book_codes"
              label="Book Codes"
              outlined
              dense
              class="pa-0 mt-3"
              v-model="form.generated_book_code_id"
              :disabled="(reusecomponent ===false && (searchBookId == '' || book_id)) ? true : false"
                
              >
              <template v-slot:append  v-if="manual_book_code">
                <v-slide-x-reverse-transition mode="out-in">
                 <p class="mb-0" style="margin-top:1px;font-weight: bold;">({{manual_book_code}})</p>
                </v-slide-x-reverse-transition>
              </template>
              </v-autocomplete>
              <!-- <p style="margin-top: -20px" v-if="manual_book_code">({{ manual_book_code }})</p> -->
              
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="resetBookIssue()"
                >Close</v-btn
              >
              <v-btn color="success" text @click="validate" :disabled="(form.generated_book_code_id === '' || form.enroll_code === '' || form.issue_till_date === '') ? true:false">Save</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="returnBook" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Book Return</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-form ref="returnform">
            <v-select
              v-model="bookreturn.status"
              :items="[
                { value: 'returned', text: 'Returned' },
                { value: 'renewed', text: 'Renew' },
              ]"
              class="pa-0"
              label="Select Staus"
              outlined
              dense
            />
            <v-calendar-field
              v-if="bookreturn.status == 'renewed'"
              id-val="renew"
              label="Renew Date"
              name="Renewdate"
              style="margin-top: -7px !important"
              v-model="bookreturn.renew_date"
              :key="returnKey"

            >
            </v-calendar-field>
            <br />
            <v-calendar-field
              v-if="bookreturn.status == 'renewed'"
              id-val="valid_till_date"
              label="Renew Till Date"
              name="ValidTillDate"
              style="margin-top: -7px !important"
              v-model="bookreturn.issue_till_date"
            >
            </v-calendar-field>
            <br />
            <v-calendar-field
              v-if="bookreturn.status !== 'renewed'"
              id-val="retured"
              label="Return Date"
              :rules="dateValidation"
              required
              name="Returndate"
              v-model="bookreturn.return_date"
              style="margin-top: -7px !important"
              :key="returnKey"
            >
            </v-calendar-field>
            <v-text-field
              autocomplete="off"
              label="Fine"
              class="pa-0 mt-3"
              outlined
              dense
              type="number"
              v-model="bookreturn.fine"
            />

            <v-textarea
              label="Description"
              no-resize
              rows="3"
              row-height="15"
              outlined
              v-model="bookreturn.remarks"
            ></v-textarea>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" text @click="reset()">Close</v-btn>
              <v-btn color="success" text @click="bookReturnUpdate()"
                >Save</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { th } from "date-fns/locale";
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

let typingTimer;
let doneTypingInterval = 400;
export default {
  mixins: [Mixins],
  props: {
    issuereport: {
      default: false,
    },
    book_id: {
      default: "",
    },
    book_isbn_number:{
      default:"",
    },
    reusecomponent: {
      default: false,
    },
  },
  data: () => ({
    book_name:"",
    student_enroll_code:"",
    student_enroll_id:"",
    compKey:0,
    returnKey:0,
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    nameRules: [(v) => !!v || "Name is required"],
    valid: true,
    lazy: false,
    returnBook: false,
    searchBookId:'',
    bookreturn: {
      enroll_code:"",
      isbn_number:'',
      status: "returned",
      return_date: new NepaliDate(new Date()).format("YYYY-MM-DD"),
      renew_date: new NepaliDate(new Date()).format("YYYY-MM-DD"),
      id: "",
      fine: "",
      remarks: "",
      book_id: "",
      issue_date:"",
      generated_book_code_id:"",
      issue_till_date:"",
      book_code:"",
    },
    downloadUrl:"",
    book_filter:{
      enroll_code: "",
      grade_id:"",
      frmDate:"",
      toDate: "",
      book_status:"all",
    },

    form: new Form(
      {
        book_id: "",
        enroll_code: "",
        issue_date: new NepaliDate(new Date()).format("YYYY-MM-DD"),
        book_isbn_number:"",
        book_code:"",
        generated_book_code_id:"",
        issue_till_date:"",
        enroll_id: "",

      },
      "library/api/book-issue"
    ),
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Book", align: "left", value: "book_name", sortable: false },
      {
        text: "Student Name",
        align: "left",
        value: "student_name",
        sortable: false,
      },
      {
        text: "Grade (Section)",
        align: "left",
        value: "grade_section",
        sortable: false,
      },
      {
        text: "Issue Date",
        align: "left",
        value: "issue_date",
        sortable: false,
      },
      {
        text: "Issue Till Date",
        align: "left",
        value: "issue_till_date",
        sortable: false,
      },
      {
        text: "Return Date",
        align: "left",
        value: "retrun_date",
        sortable: false,
      },
      {
        text: "Status",
        align: "left",
        value: "status",
        sortable: false,
      },
      { text: "Action", align: "right", value:'action', sortable: false },
    ],
    book_codes:[],
    search:"",
    showIssueDialog:false,
    display_book:false,
    searchbook:'',
    bookLists:[],
    book_status:[
      {text:"All",value:'all'},
      {text:"Issued",value:'issued'},
      {text:"Returned",value:'returned'},
      {text:"Renew",value:'renewed'}
    ],
    manual_book_code:'',
  }),
  mounted(){
    if(this.reusecomponent) {
      this.showIssueDialog = this.reusecomponent;
      this.getBookCode();
    }
  },
  watch: {
    searchbook(val){
            if (!val) return;

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
      $this.getBooks();
      }, doneTypingInterval);
    },
    search(val) {
      if (!val) return;

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function() {
       $this.getBookCode();
      }, doneTypingInterval);
    },
    pagination: function () {
      this.get();
    },
    book_name(val) {
      if (!val) return this.get();

      if (val.length < 1) return;

      // Items have already been requested
      if (this.loading) return;

      let $this = this;
      clearTimeout(typingTimer);
      typingTimer = setTimeout(function () {
        $this.get();
      }, doneTypingInterval);
    },

  },
  computed: {
    showHeaders() {
      let filteredArray= '';
      return filteredArray = this.headers.filter(function(e) { return e.value !== 'action' })

    },
  },
  methods: {
    onClear(){
      this.student_enroll_code = ''
      this.student_enroll_code = ''
      // this.book_name = '';
      this.get();
    },
    getBooks(){
      this.$rest.get('/library/api/book?&search_query='+this.searchbook)
      .then((res)=>{
        var bookLists = res.data.data;
        this.bookLists = bookLists.map((book) => {
          return { text: book.name, value: book.id };
        });
      }).catch((err)=>{

      })
      
    },
    get(params) {
      // let dateInstance = new NepaliDate(new Date()).format("YYYY-MM-DD");
      let extraparams =
        "&book_id=" +
        (this.$route.query.book_id != undefined
          ? this.$route.query.book_id
          : "") +
        "&enroll_code=" +
        this.book_filter.enroll_code+
        "&grade_id="+
        this.book_filter.grade_id+
        "&frm_date="+
        this.book_filter.frmDate+
        "&to_date="+
        this.book_filter.toDate+
        "&book_status="+this.book_filter.book_status+"&student_enroll_code="+this.student_enroll_code+"&book_name="+this.book_name;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraparams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.downloadUrl = data.download_url;
        this.pagination.totalItems = data.meta.total;
      });
    },
    getBookCode(){
      // searchBookId
      let book_id = this.book_id
      if(this.display_book){
        book_id = this.searchBookId;
      }
      // this.$rest.get('/library/api/get-book-code?book_id='+this.book_id +"&search="+this.search)
      this.$rest.get('/library/api/get-book-code?book_id='+book_id +"&search="+this.search)
      .then((res)=>{
        var bookcode = res.data;
        this.book_codes = bookcode.map((bookcode) => {
          return { text: bookcode.book_code , value: bookcode.id };
        });
        this.form.book_code = bookcode[0].book_code
        this.form.generated_book_code_id = bookcode[0].id
        this.manual_book_code = bookcode[0].manual_book_code

      })
      .catch((err)=>{
        //eror here
      })
    },
    resetBookIssue() {
      this.showIssueDialog = false;
      this.form.reset();
      this.form.book_id = '';
      this.form.enroll_code = '';
      this.form.enroll_id = '';
      this.form.issue_till_date = '';
      this.form.book_isbn_number = '';
      this.form.generated_book_code_id='';
      this.bookLists = [];
      this.searchBookId = ''
      this.searchbook =''
      this.$emit("resetBookIssue");
    },
    filterByStudent(student){
      // this.form.enroll_code = student.enroll_code;
      this.student_enroll_code = student.enroll_code;
      this.student_enroll_id = student.enroll_id;

      setTimeout(() => {
        this.get();
      }, 200);

    },
    selectStudent(student) {
      if(student){
        if(!this.issuereport){
          this.form.enroll_code = student.enroll_code;
          this.student_enroll_code = student.enroll_code;

          this.form.enroll_id = student.enroll_id;
          this.student_enroll_id = student.enroll_id;
        }else{
          this.form.enroll_code = student.enroll_code;
          this.book_filter.enroll_code = student.enroll_code

          this.form.enroll_id = student.enroll_id;
          this.book_filter.enroll_id = student.enroll_id
        }
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.form.book_id = this.book_id;
        if(this.display_book){
          this.form.book_id = this.searchBookId;
        }
        this.form.book_isbn_number = this.book_isbn_number;

        this.form.store().then(() => {
          this.$refs.form.reset();
          this.compKey = this.compKey+1;
          this.form.issue_date=new NepaliDate(new Date()).format("YYYY-MM-DD"),

          this.resetBookIssue();
        });
      }
    },
    bookReturnUpdate() {
      if (
        this.bookreturn.return_date == "" &&
        this.bookreturn.renew_date == ""
      ) {
        return this.$events.fire("notification", {
          message: "Retrun or Renew Date is required",
          status: "error",
        });
      }
      if(this.bookreturn.status === 'renewed' && this.bookreturn.renew_date == "" && this.bookreturn.issue_till_date == ""){
        return this.$events.fire("notification", {
          message: "Renew Date and Valid Till date is required",
          status: "error",
        });
      }
      this.$rest
        .put("library/api/book-issue/" + this.bookreturn.isbn_number + "?id="+this.bookreturn.book_id, {
          ...this.bookreturn,
        })
        .then((res) => {
          if (res.status == 200) {
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
            this.reset();
            this.get();

          }
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    reset() {
      this.returnBook = false;
      this.$refs.returnform.reset();
      this.bookreturn.status = "returned";
      this.form.issue_date= new NepaliDate(new Date()).format("YYYY-MM-DD");
    },
  },
};
</script>